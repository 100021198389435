<template>
  <div class="apply1">
    <van-cell-group :border="false">
      <van-cell title-class="biaoti" :border="false" title="欢迎使用大咖联盟供货系统" />
      <van-cell title-class="content" label-class="tishi" :border="false" label="你需充分阅读并理解本政策的内容。若你同意，请点击下方按钮开始接受我们的服务。">
        <template #title>
          我们将通过<span class="alink" @click="yinsi">《隐私政策》</span>和<span class="alink" @click="xieyi">《用户协议》</span>帮助你了解我们收集、使用、存储和共享个人信息的情况。特别是我们所采集的个人信息类型与用途的对应关系。此外，你还能了解你所享用的权利及实现途径，以及我们为保护你的个人信息所采用的安全技术。你需充分阅读并理解本政策的内容。若你同意，请点击下方按钮开始接受我们的服务。
        </template>
      </van-cell>
    </van-cell-group>
    <div class="btn">
      <van-button block type="danger" @click="commition">同意协议</van-button>
      <van-button block hairline @click="faile">不同意并退出</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GonghuoApply1",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "供货申请");
  },
  methods: {
    commition() {
      // 已经登陆直接进入申请页面
      this.$router.push({
        name: "ApplyService",
        query: {
          shareid: this.shareid,
          merchid: this.merchid,
          t: new Date().getTime()
        }
      });
    },
    
    faile() {
      this.$router.back();
    },

    // 用户隐私政策
    yinsi() {
      this.$router.push({
        name: "yinSi",
        query: {
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    // 用户用户协议
    xieyi() {
      this.$router.push({
        name: "xieYi",
        query: {
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
  }
}
</script>
<style lang="less">
.apply1 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-cell-group {
    padding: 0;
    .van-cell {
      .van-cell__title {
        flex: 1;
        .alink {
          color: #1989FA;
          text-decoration: underline;
          margin: 0 5px;
        }
      }
      .biaoti {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        padding: 40px 16px 20px;
      }
      .content {
        font-size: 14px;
        color: #333;
        text-align: left;
        margin-bottom: 20px
      }
      .tishi {
        font-size: 12px;
        color: #999;
        text-align: left;
        margin-top: 20px
      }
    }
  }
  .btn {
    padding: 20px;
    overflow: hidden;
    .van-button {
      margin-top: 20px;
    }
  }
}
</style>